.main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

@media only screen and (max-width: 700px) {
  .main-container {
    display: flex;
    flex-direction: column-reverse;
  }
}
