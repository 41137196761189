.root {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.dataContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.loaderContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.topContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  max-height: 40px;
  margin-bottom: 10px;
}
.title {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
  padding-bottom: 10px;
}
.createBtn {
  padding: 10px 30px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-weight: 700;
}
.tableContainer {
  display: flex;
  flex: 7;
  height: 100%;
}
.editBtn {
  background-color: transparent;
  color: blue;
}
.form {
  display: flex;
  align-items: center;
  justify-content: center;
}
.formGroup {
  margin: 0px 7px;
}
.formBtn {
  margin: 0px 7px !important;
}
